import {
  Button,
  ButtonGroup,
  Calendar,
  Card,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Divider,
  Header,
  Input,
  Message,
  Popover,
  Radio,
  Select,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tooltip,
  RadioButton,
  RadioGroup,
  Pagination,
  Breadcrumb,
  BreadcrumbItem
} from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils';
// import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
locale.use(lang);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Header.name, Header);
Vue.component(Card.name, Card);
Vue.component(Input.name, Input);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Popover.name, Popover);
Vue.component(Divider.name, Divider);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Calendar.name, Calendar);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Dialog.name, Dialog);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Pagination.name, Pagination);
Vue.component(Breadcrumb.name, Breadcrumb);
Vue.component(BreadcrumbItem.name, BreadcrumbItem);
Vue.prototype.$message = Message;

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
Vue.config.productionTip = false;
// Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
