import Cookies from 'js-cookie';

const userInfoString = localStorage.getItem('userInfo');
const userInfo = JSON.parse(userInfoString || '{}');
const state = {
  isNull: true,
  userName: userInfo.userName || '',
  userEmail: userInfo.userEmail || '',
  userPhone: userInfo.userPhone || '',
  userAvatar: userInfo.userAvatar || '',
};

const getters = {
  getUserAvatar(state) {
    return state.userAvatar || require('@/assets/icon.png');
  },
  getUserEmail(state) {
    return state.userEmail;
  },
  getUserName(state) {
    return state.userName;
  },
  getUserPhone(state) {
    return state.userPhone;
  },
  getUserAll(state) {
    return state;
  },

};

const actions = {
  saveUserToken({ commit, }, token) {
    // console.log('aaa', token);
    return new Promise((resolve, reject) => {
      try {
        commit('SAVE_USER_TOKEN', token);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  saveUserInfo({ commit, }, info) {
    // console.log('aaa', info);
    return new Promise((resolve, reject) => {
      try {
        commit('SAVE_USER_INFO', info);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  logout({ commit, dispatch, }) {
    return new Promise((resolve, reject) => {
      try {
        dispatch('router/delAllRoute', null, { root: true, });
        commit('LOGOUT');
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  SAVE_USER_TOKEN(state, token) {
    state.token = token;
    Cookies.set('student-token', token, {
      expires: 7,
    });
  },
  SAVE_USER_INFO(state, userInfo) {
    state.isNull = false;
    state.userAvatar = userInfo.userAvatar;
    state.userEmail = userInfo.userEmail;
    state.userName = userInfo.userName;
    state.userPhone = userInfo.userPhone;
  },
  LOGOUT(state) {
    // 这里只是在本地模拟删除了用户信息，在真实场景下需要 调后台登出接口 来真正实现登出功能
    state.userName = '';
    state.userNickName = '';
    state.userPhone = '';
    state.userAvatar = '';
    Cookies.remove('student-token');
    localStorage.removeItem('userInfo');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
