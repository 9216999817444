<template>
  <div class="layout-view">
    <Head-bar />
    <section class="layout-container-box">
      <section class="layout-container">
        <router-view />
      </section>
    </section>
  </div>
</template>

<script>
import { HeadBar } from './components';
export default {
  name: 'LayoutsView',
  components: {
    HeadBar,
  },
};
</script>

<style>
.layout-view{
  min-height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;

}
.layout-container-box{
  box-sizing: border-box;
  /* min-width: 1175px; */
  height: 100%;
}
.layout-container-box > .layout-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
