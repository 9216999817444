import './router';
/** 计算 byte 并返回文字
 * @param {int} size
 */
export function sizeToString(size) {
  const number = 1024;
  if (size < number) {
    return size + 'b';
  }
  size = Math.floor(size / 1024);
  if (size < number) {
    return size + 'kb';
  }
  size = Math.floor(size / 1024);
  return size + 'mb';
}
