import Vue from 'vue';
import VueRouter from 'vue-router';
import Layouts from '../layouts/LayoutsView.vue';
import HomeView from '../views/HomeView.vue';

// 这里捕获重复跳转错误让他不显示
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  // console.log(location);
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/index/lessons/Upcoming',
    hidden: true,
  },
  {
    path: '/index/lessons',
    name: 'lessons',
    redirect: '/index/lessons/Upcoming',
    hidden: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    hidden: true,
    children: [
      {
        path: 'sginin',
        name: 'sginin',
        component: () => import('@/views/login/sgin-in.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/login/register.vue'),
      },
      {
        path: 'resetEmail',
        name: 'resetEmail',
        component: () => import('@/views/login/reset-email.vue'),
      },
      {
        path: 'resetCode',
        name: 'resetCode',
        component: () => import('@/views/login/reset-code.vue'),
      },
      {
        path: 'resetpad',
        name: 'resetpad',
        component: () => import('@/views/login/reset-password.vue'),
      },
    ],
  },
  {
    path: '/index',
    name: 'index',
    component: Layouts,
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
      },
      {
        path: 'lessons',
        name: 'lessons',
        component: () => import('../views/lessons'),
        children: [
          {
            path: 'calendar',
            name: 'calendar',
            component: () => import('../views/lessons/lessons-calendar.vue'),
          },
          {
            path: 'details/:id',
            name: 'details',
            component: () => import('../views/lessons/lessons-details.vue'),
          },
          {
            path: ':type',
            name: 'lessonsType',
            component: () => import('../views/lessons/lessons-list.vue'),
          },
        ],
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/messages'),
        children: [
        ],
      },
      {
        path: 'operation',
        name: 'operation',
        redirect: 'operation/askOff',
        component: () => import('@/views/operation'),
        children: [
          {
            path: 'askOff',
            name: 'askOff',
            component: () => import('@/views/operation/askOffView.vue'),
          },
          {
            path: 'cleanLesson',
            name: 'cleanLesson',
            component: () => import('@/views/operation/cleanLessonView.vue'),
          },
        ],
      },
      {
        path: 'user',
        name: 'user',
        redirect: 'user/personal',
        component: () => import('@/views/user'),
        children: [
          {
            path: 'usePassword',
            name: 'usePassword',
            component: () => import('@/views/user/user-password.vue'),
          },
          {
            path: 'guardian',
            name: 'guardian',
            component: () => import('@/views/user/user-guardian.vue'),
          },
          {
            path: 'personal',
            name: 'personal',
            component: () => import('@/views/user/personal-details.vue'), },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/index/lessons/Upcoming',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
