import { Message as message } from 'element-ui';
import Vue from 'vue';
import request from './axios.config';

// http
const http = function({ url, data, method = 'GET', headers, beforeRequest, afterRequest, }) {
  const successHandler = res => {
    // console.log(url, data, res.result);
    if (!res.code || res.code === 200) {
      return res;
    }
    message({
      message: res.msg || res.message || '请求失败，未知异常',
      type: 'error',
    });
    throw new Error(res.message || '请求失败，未知异常');
  };
  const failHandler = error => {
    afterRequest && afterRequest();
    message({
      message: error.msg || error.message || '请求失败，未知异常',
      type: 'error',
    });
    // console.log('请求失败，未知异常', url, data, error);
    throw new Error(error.message || '请求失败，未知异常');
  };
  beforeRequest && beforeRequest();
  switch (method) {
    case 'GET':
    case 'get':
      return request.get(url, { params: data, ...headers, }).then(successHandler, failHandler);
    case 'POST':
    case 'post':
      return request.post(url, data, { headers, }).then(successHandler, failHandler);
    case 'DELETE':
    case 'delete':
      return request.delete(url, { ...headers, data, }).then(successHandler, failHandler);
    case 'PUT':
    case 'put':
      return request.put(url, data, { headers, }).then(successHandler, failHandler);
    default:
      break;
  }
};
Vue.prototype.$http = http;
// get方法
function get({ url, data, beforeRequest = null, afterRequest = null, }) {
  return this.$http({ url, method: 'GET', data, beforeRequest, afterRequest, });
}
Vue.prototype.$get = get;

// post方法
function post({ url, data, headers, beforeRequest = null, afterRequest = null, }) {
  // console.log(url, data, headers, beforeRequest, afterRequest);
  return this.$http({ url, method: 'POST', data, headers, beforeRequest, afterRequest, });
}
Vue.prototype.$post = post;

export { http };

