<template>
  <div class="layouts-header">
    <div class="header-box">
      <section class="nav-box">
        <!-- 标签区 -->
        <div class="img-box" style="width: 80px">
          <img :src="iconTitle" alt="" srcset="" />
        </div>
        <!-- 导航区 -->
        <routerItem class="big-font" to="/index/lessons" :is-show="false">Lessons</routerItem>
        <routerItem class="big-font" to="/index/message" :is-show="false">Messages</routerItem>
        <routerItem class="big-font" to="/index/operation" :is-show="false">Operation center
        </routerItem>
      </section>
      <!-- 个人用户区 -->
      <section>
        <!-- <routerItem to="/index/user" :is-show="false"> -->
        <el-popover
          placement="bottom-end"
          width="200"
          trigger="hover"
          popper-class="personal-box"
        >
          <div class="personal">
            <div class="title">{{ $store.getters["user/getUserName"] }}</div>
            <div class="text">Student</div>
            <div class="line"></div>
            <div
              class="item"
              :class="{ active: path.includes('/index/user') }"
              @click="jump"
            >
              profile
            </div>
            <div class="item" @click="signOut">sign out</div>
          </div>
          <div slot="reference">
            <i class="el-icon-user-solid"></i>
            <span style="display: inline-block; width:10px"></span>
            <span class="small-font ">{{ $store.getters["user/getUserName"] }}</span>
          </div>
        </el-popover>
      </section>
    </div>
  </div>
</template>

<script>
import iconTitle from '@/assets/iconTitle.png';
import Cookies from 'js-cookie';
import routerItem from './router-item.vue';

export default {
  name: 'HeadBar',
  components: {
    routerItem,
  },
  data: () => ({
    path: '',
    iconTitle,
  }),
  watch: {
    $route(to, from) {
      this.path = to.fullPath;
    },
  },
  created() {
    this.path = this.$route.path;
  },
  methods: {
    jump() {
      this.$router.push({
        path: '/index/user/personal',
      });
    },
    signOut() {
      // console.log('退出登录');
      Cookies;
      // Cookies.remove('student-token');
      this.$router.replace({
        path: '/login/sginin',
      });
    },
  },
};
</script>

<style scoped>
.layouts-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #ebeef1;
}
.nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-box {
  padding-top: 20px;
  padding-bottom: 16px;
  padding-right: 12px;
}
.header-box {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  min-width: 1170px;
  max-width: 1600px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  letter-spacing: -0.04rem;
  color: rgba(15, 23, 42, 1);
}
.header-box a {
  color: rgba(15, 23, 42, 1);
}
/** 弹出框  */
.personal {
  padding-top: 12px;
}
.personal > .title {
  padding: 0 12px;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0px;
  color: #0f172a;
}
.personal > .line {
  height: 1px;
  background-color: #ebeef1;
  margin-top: 10px;
}
.personal > .text {
  padding: 0 12px;
  font-size: 12px;
  letter-spacing: 0px;
  color: #858d99;
}
.personal > .item {
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #1e293b;
}
.personal > .item.active {
  color: #122a88;
}
.personal > .item:hover {
  background-color: #f6f8fa;
}
</style>
<style>
.el-popover.personal-box {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}
</style>
