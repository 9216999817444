<!-- 导航焦点 -->
<template>
  <div class="router-item">
    <div class="text-box">
      <router-link :to="to">
        <slot></slot>
      </router-link>
      <div class="tips-line" :class="{'active':isShow}"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RouterItem',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '/',
    },
  },
};
</script>

<style>
.router-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #0f172a;
  margin: 0 10px;
  cursor: pointer;
}
.text-box {
	font-size: 16px;
	color: #0f172a;
  position: relative;
}
.tips-line{
  display: none;
  position: absolute;;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60%;
	height: 0.4rem;
	background-color: #122a88;
	border-radius: 2px;
  margin-top: 5px;
}
.router-item:hover .tips-line{
  display: block;
}
.router-item .tips-line.active{
  display: block;
}
</style>
