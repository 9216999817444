<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style>
*{
  box-sizing: border-box;
}
:root{
  /* 控制1rem=10px 对于 1920宽度 */
  font-size: calc(1200 / 1920 * 10px);
  --heightem:calc(100vh / 1080 * 10);
}
@media screen and ( min-width: 1200px){
  :root{
    font-size:calc(100vw / 1920 * 10)
  }

}
body,html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body{
  font-size: 16px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgba(251, 251, 255, 1);
  width: 100%;
  min-height: 100vh;
}

nav {
  padding: 30px;
}
.text-box a {
  font-weight: 400;
  color: #2c3e50;
  text-decoration: none;
}
a:visited{
  color: initial;
}
nav a.router-link-exact-active {
  color: #42b983;
}
.big-font{
  font-size: 2.4rem;
  font-weight: 800;
}
/* 小字体 */
.small-font{
  font-size: 14px;
  font-weight: 400;
}
.img-box>img{
  width: 100%;
  display: block;
}
a{
  text-decoration: none;
}
body .el-popover,.el-popper{
 padding: 0;
 border-radius: 10px;
}
</style>
