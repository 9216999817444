import Vuex from 'vuex';
import { http } from './http';
Vuex.Store;
/**
 * @function 邮箱和密码登录
 * @param {Object} object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.loginPassword - 密码
 * @returns {Object}
 */
export function sginInOfPassword({ userEmail, loginPassword, }) {
  // console.log('登录 邮件:', userEmail, '密码:', loginPassword);
  return http({
    url: '/api/auth/loginWithPhoneAndPwd',
    method: 'post',
    data: {
      userEmail,
      loginPassword,
    },
  });
}
/**
 * @function 邮箱和验证码登录
 * @param {Object} object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.code - 密码
 * @returns {Object}
 */
export function sginInOfCode({ userEmail, code, }) {
  // console.log('登录:', userEmail, '验证码:', code);
  return http({
    url: '/api/auth/loginWithPhoneAndCode',
    method: 'post',
    data: {
      userEmail,
      code,
    },
  });
}

/**
 * @function 获取邮箱验证码
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @returns {Object}
 */
export function getEmailCode({ userEmail, }) {
  // console.log('获取邮箱验证码', {
  //   userEmail,
  // });
  return http({
    url: '/api/auth/getEmailCode/' + userEmail,
    method: 'GET',
  });
}

/**
 * @function 学生注册
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.loginPassword - 密码
 * @param {String} object.code - 验证码
 * @param {String} object.userName - 名字
 * @returns {Object}
 */
export function registerStudent({ userEmail, loginPassword, code, userName, }) {
  // console.log('注册', {
  //   userEmail,
  //   loginPassword,
  //   code,
  //   userName,
  // });
  return http({
    url: '/api/auth/registerStudent',
    method: 'POST',
    data: {
      userEmail,
      loginPassword,
      code,
      userName,
    },
  });
}

/**
 * @function 重置密码
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.newLoginPassword - 密码
 * @param {String} object.code - 验证邮箱码
 * @returns {Object}
 */
export function resetPassword({ userEmail, newLoginPassword, code, }) {
  // // console.log('重置密码', {
  //   userEmail,
  //   loginPassword,
  //   code,
  // });
  return http({
    url: '/api/auth/resetPassword',
    method: 'post',
    data: {
      userEmail,
      newLoginPassword,
      code,
    },
  });
}

/**
 * @function 验证码验证
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.code - 验证邮箱码
 * @returns {Object}
 */
export function resetPasswordCode({ userEmail, code, }) {
  // console.log('验证码验证', {
  //   userEmail,
  //   code,
  // });
  return http({
    url: '/api/auth/resetPasswordCode',
    method: 'post',
    data: {
      userEmail,
      code,
    },
  });
}

/**
 * @function 修改密码
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.loginPassword - 原密码
 * @param {String} object.newLoginPassword - 新密码
 * @param {String} object.code - 验证邮箱码
 * @returns {Object}
 */
export function updatePassword({
  userEmail,
  loginPassword,
  newLoginPassword,
  code,
}) {
  // console.log('修改密码', {
  //   userEmail,
  //   loginPassword,
  //   newLoginPassword,
  //   code,
  // });
  return http({
    url: '/api/auth/updatePassword',
    method: 'post',
    data: {
      userEmail,
      loginPassword,
      newLoginPassword,
      code,
    },
  });
}

/**
 * @function 修改用户信息
 * @param {Object} Object
 * @param {String} object.userEmail - 邮箱
 * @param {String} object.userPhone - 用户手机
 * @param {String} object.userName - 名称
 * @param {String} object.userAvatar - 用户头像
 * @param {String} object.code - 验证码
 * @returns {Object}
 */
export function updateUserInfo({
  userEmail,
  newUserEmail,
  userPhone,
  userName,
  userAvatar,
  code,
}) {
  // console.log('修改用户信息', {
  //   userEmail,
  //   newUserEmail,
  //   userPhone,
  //   userName,
  //   userAvatar,
  //   code,
  // });
  return http({
    url: '/api/auth/updateUserInfo',
    method: 'post',
    data: {
      userEmail,
      newUserEmail,
      userPhone,
      userName,
      userAvatar,
      code,
    },
  });
}

/**
 * @function 获取用户消息
 * @param {Vuex.Store} store -vuex 好tm高的耦合?谁这样传的
 */
export async function getStudentInfo(store) {
  let res = null;
  var obj = store.getters['user/getUserAll'];
  if (obj['isNull']) {
    res = await http({
      url: '/api/auth/getStudentInfo',
      method: 'GET',
    });
    store.dispatch('user/saveUserInfo', res.result);
    return res;
  } else {
    res = {
      code: 200,
      message: '成功!',
      result: {
        userName: obj['userName'],
        userEmail: obj['userEmail'],
        userPhone: obj['userPhone'],
        userAvatar: obj['userAvatar'],
      },
    };
    return res;
  }
}

// ########## 添加监护人邮箱
/**
 * 设置监护人邮箱
 * @export
 * @param {object} obj { email, name, phone, }
 * @param {string} obj.email 邮箱
 * @param {string} obj.name 名字
 * @param {string} obj.phone 手机
 */
export async function updateUserCertification({ email, name, phone, }) {
  return http({
    url: '/api/auth/updateUserCertification',
    method: 'post',
    data: { email, },
  });
}
/**
 * 获取监护人邮箱
 * @export
 */
export async function getUserCertification() {
  return http({
    url: '/api/auth/getUserCertification',
    method: 'GET',
  });
}
